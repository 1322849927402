/// <reference path="./lib/jquery.d.ts" />
namespace Table{
    export let $table = $("article").find("table");
}

$(function(){
    if(Table.$table.length){
        $(Table.$table).each(function(){
            let $this = $(this);
            let t_class : Boolean = $this.hasClass('');
            let ckt_class : Boolean = $this.hasClass('ck__table');
            if(t_class === true) {
                $this.wrap('<div class="table-wrap"></div>');
            }else if(ckt_class === true){
                $this.wrap('<div class="table-wrap"></div>');
            }
        });
    }
});