/// <reference path="./lib/jquery.d.ts" />

namespace Accordion{
    export let $foot_accordion : any = $('.accordion');
    export let $drawer_accordion : any = $('.accordion');
}

$(function(){
    // Accordion.$foot_accordion.on('touchend click', function(e) {
    //     let $this = $(this);
    //     e.stopPropagation();
    //     e.preventDefault();
    //     if(e.type == "touchend") {
    //         f_accordion();
    //     }else{
    //         f_accordion();
    //     }
    //     function f_accordion(){
    //         if($('html').is('.width-sp')){
    //             $this.toggleClass("active").children("ul").slideToggle(300);
    //         }
    //     }
    // });


    Accordion.$drawer_accordion.on('click', function(e) {
        let $this = $(this);
        if(e.type == "touchend") {
            f_accordion();
        }else{
            f_accordion();
        }
        function f_accordion(){
            if($('html').is('.width-sp')){
                $this.toggleClass("active").parent().children("ul").slideToggle(300);
            }
        }
    });

    // $("li.global-menu__list").on('touchstart click', function(e) {
    //     let $this = $(this);
    //     if(e.type == "touchstart") {
    //         sp_list_accordion();
    //     }else{
    //         sp_list_accordion();
    //     }
    //     function sp_list_accordion(){
    //         if($('html').is('.width-sp')){
    //             let $id = '#sp-' + $this.attr('id');
    //             let $class = $($id).hasClass('active');
    //             if( $class === true){
    //                 $($id).removeClass("active").slideUp(300);
    //             }else{
    //                 if($(".sp-menu-wrap").find(".active").length){
    //                     $.when(
    //                         $(".sp-menu-wrap").find(".active").slideUp(300).removeClass("active")
    //                     ).done(function(){
    //                         $($id).addClass("active").slideDown(300);
    //                     });
    //                 }else{
    //                     $($id).addClass("active").slideDown(300);
    //                 }
    //             }
    //         }
    //     }
    // });
});