/// <reference path="./lib/jquery.d.ts" />

namespace Slide{
    export const $wrap : any = $('#slide');
}

$(function(){
    if(Slide.$wrap.length){
        let $img: any = Slide.$wrap.children('img');
        let $num: number = $img.length;
        let $count: number = 0;
        let $interval: number = 5000;
        $img.eq(0).addClass("show");
        setTimeout(slide, $interval);
        function slide(){
            $img.eq($count).removeClass("show");
            $count++;
            if($count >= $num) {
                $count = 0;
            }
            $img.eq($count).addClass("show");
            setTimeout(slide, $interval);
        }
    }
});