/// <reference path="./lib/jquery.d.ts" />

namespace Tel{
}

$(function(){
    let ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0){
        $('.tel-link').each(function(){
            let $str = $(this).text();
            $(this).wrap("<a class='tel-push' href='tel:" + $str + "'></a>");
        });
    }else{
        $('.tel-icon-link').attr('href','javascript:void(0)');
    }
});