/// <reference path="./lib/jquery.d.ts" />

namespace Hover{
    export let $hover : any = $('.global-menu__list').find('li');
}

$(function(){
    Hover.$hover.bind( 'touchstart', function(){
        $(this).addClass('hover');
    }).bind( 'touchend', function(){
        $(this).removeClass('hover');
    })
});