/// <reference path="./lib/jquery.d.ts" />

namespace Responsive{
    export let $breakA : number = 768;
    export let $html            = $("html");
}

$(window).on("load resize orientationchange",function(){
    let $width : number  = window.innerWidth ? window.innerWidth: $(window).width();
    if($width > Responsive.$breakA){
        Responsive.$html.addClass('width-pc');
        Responsive.$html.removeClass('width-sp');
    }else{
        Responsive.$html.addClass('width-sp');
        Responsive.$html.removeClass('width-pc');
    }
});