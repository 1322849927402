/// <reference path="./lib/jquery.d.ts" />

// -------------------------------------------------------------------
// 変数
// -------------------------------------------------------------------
namespace Agent{
    export let $agent: any = navigator.userAgent; // ユーザーエージェント
    export let $html : HTMLElement = document.documentElement; // html要素
}

// UserAgent.$Agentで振り分けてhtmlにclassを振る
// -------------------------------------------------------------------
// Mac のとき
if (navigator.platform.search("Mac") != -1) {
    Agent.$html.className += ' mac';
}

// Windows のとき
if(Agent.$agent.search("Windows") != -1){
    Agent.$html.className += ' win';

    if((Agent.$agent.search("Trident") != -1) || ((Agent.$agent.search("MSIE") != -1))) { // Internet Explorer All
        Agent.$html.className += ' ie';

        if(Agent.$agent.search("Trident") != -1) { // IE8以上
            Agent.$html.className += ' gte_ie8';
        }
        if(Agent.$agent.search("Trident") == -1) { // IE7以下
            Agent.$html.className += ' lte_ie8';
        }
        if(Agent.$agent.search("MSIE 7") != -1){ // 一応IE7
            Agent.$html.className += ' ie7';
        }
        if(Agent.$agent.search("MSIE 8") != -1){ // IE8
            Agent.$html.className += ' ie8';
        }
        if(Agent.$agent.search("MSIE 9") != -1){ // IE9
            Agent.$html.className += ' ie9';
        }
        if(Agent.$agent.search("MSIE 10") != -1){ // IE10
            Agent.$html.className += ' ie10';
        }
        if(Agent.$agent.search("Trident/7") != -1){ // IE11
            Agent.$html.className += ' ie11';
        }
    }
}

if((Agent.$agent.search('Edge') != -1)){
    // Edge
    Agent.$html.className += ' edge';
}else if((Agent.$agent.search("Chrome") != -1) && (Agent.$agent.search("OPR") == -1)){
    // Chrome のとき
    Agent.$html.className += ' chrome';
}else if((Agent.$agent.search("Safari") != -1) && (Agent.$agent.search("Chrome") == -1) && (Agent.$agent.search("OPR") == -1) && (Agent.$agent.search("Presto") == -1)) {
    // Safari のとき
    Agent.$html.className += ' safari';
}else if(Agent.$agent.search("Firefox") != -1) {
    // Firefox のとき
    Agent.$html.className += ' firefox';
}

// iPad のとき
if(Agent.$agent.search("iPad") != -1){ Agent.$html.className += ' ipad'; }

// iPhone のとき
if(Agent.$agent.search("iPhone") != -1){ Agent.$html.className += ' iphone'; }

// Android のとき
if(Agent.$agent.search("Android") != -1){ Agent.$html.className += ' android'; }