/// <reference path="./lib/jquery.d.ts" />

namespace Tile{
    export let $ec_txt : any = $(".ec_txt");
    export let $top_button_list : any = $(".top-button-list").find("li").find("a");
    export let $genre_list : any = $(".genre-list").find("li").find("a");
    export let $column_list__second : any = $(".column-list--second").find("li");
    export let $column_list__three : any = $(".column-list--three").find("li");
    export let $route_list : any = $(".route-list").find("li");
    //top
    export let $fairing_list : any = $(".fairing-list").find("li").find(".fairing-list__txt");
}

$(window).on("load resize orientationchange",function(){
    if(Tile.$ec_txt.tile.length){
        if($('html').is('.width-pc')){
            Tile.$ec_txt.tile(5);
        }else if($('html').is('.width-sp')){
            Tile.$ec_txt.tile(2);
        }
    }

    if(Tile.$top_button_list.length){
        if($('html').is('.width-pc')){
            $(".top-button-list").each(function(){
                $(this).find("li").find("a").tile();
            });
        }else if($('html').is('.width-sp')){
            Tile.$genre_list.removeAttr('style');
        }
    }

    if(Tile.$genre_list.length){
        if($('html').is('.width-pc')){
            $(".genre-list").each(function(){
                $(this).find("li").find("a").tile();
            });
        }else if($('html').is('.width-sp')){
            Tile.$genre_list.removeAttr('style');
        }
    }

    if(Tile.$column_list__second.length){
        if($('html').is('.width-pc')){
            $(".column-list--second").each(function(){
                $(this).find("li").tile(2);
            });
        }else if($('html').is('.width-sp')){
            Tile.$column_list__second.removeAttr('style');
        }
    }

    if(Tile.$column_list__three.length){
        if($('html').is('.width-pc')){
            $(".column-list--three").each(function(){
                $(this).find("li").tile(3);
            });
        }else if($('html').is('.width-sp')){
            Tile.$column_list__three.removeAttr('style');
        }
    }

    if(Tile.$fairing_list.length){
        if($('html').is('.width-pc')){
            $(".fairing-list").each(function(){
                $(this).find("li").find(".fairing-list__txt").tile(4);
            });
        }else if($('html').is('.width-sp')){
            Tile.$fairing_list.removeAttr('style');
        }
    }


    if(Tile.$route_list.length){
        if($('html').is('.width-pc')){
            $(".route-list").each(function(){
                $(this).find("li").tile(3);
            });
        }else if($('html').is('.width-sp')){
            Tile.$route_list.removeAttr('style');
        }
    }
});