/// <reference path="./lib/jquery.d.ts" />

namespace Pagetop{
    export let $pagetop : any = $('#pagetop-pc');
    export let $pagetop_sp : any = $('#pagetop-sp');
}

$(function(){
    Pagetop.$pagetop.css('bottom', '-100px')
    let showFlag = false;
    $(window).scroll(function(){
        if($(this).scrollTop() > 100){
            if(showFlag === false){
                showFlag = true;
                Pagetop.$pagetop.stop().animate({'bottom' : '20px'}, 200);
            }
        }else{
            if(showFlag){
                showFlag = false;
                Pagetop.$pagetop.stop().animate({'bottom' : '-100px'}, 200);
            }
        }
    });
    //スクロールしてトップ
    Pagetop.$pagetop.click(function(){
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    //スクロールしてトップ
    Pagetop.$pagetop_sp.click(function(){
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});