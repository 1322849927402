/// <reference path="./lib/jquery.d.ts" />


$(function () {

  $("article").find("a").each(function(){
    var name = $(this).attr('name');
    if(name != undefined){
      var aText = $(this).html();
      if(aText == ''){
        $(this).css("height" ,"1px");
        $(this).css("display" ,"inline-block");
        $(this).css("vertical-align" ,"top");
      }
    }
  });


  $("a[href^='#']").click(function(){
      var mt = 0;
      var id = $(this).attr('href');
      var name  = id.replace("#", "");
      if($(id).attr('name') == name){
        if($('h2').find(id).length){
          mt = 24;
        }
      }
      if( $('html').hasClass('width-sp')){
          var headerHight = 72;
          var href = $(this).attr("href");
          var target = $(href == "#" || href == "" ? 'html' : href);
          var position = target.offset().top - headerHight - mt;
          $("html, body").animate({scrollTop:position}, 500, "swing");
          return false;
      }else if( $('html').hasClass('width-pc')){
          var headerHight = 0;
          var href = $(this).attr("href");
          var target = $(href == "#" || href == "" ? 'html' : href);
          var position = target.offset().top - headerHight - mt;
          $("html, body").animate({scrollTop:position}, 500, "swing");
          return false;
      }
  });
  $(".mainnav__list").find("li").click(function(){
    var liurl = $(this).find('a').attr("href");
    var headerHight = 72;
    if(liurl.indexOf("#") != -1){
        location.reload();
    }
  });

  $(window).on('load', function() {
    if( $('html').hasClass('width-sp')){
      var headerHight = 72;
      var url = location.href
      var hash = location.hash;
      if(url.indexOf("#") != -1){
        var target = $(hash);
        if(target.length){
          var position = target.offset().top-headerHight;
          $("html, body").animate({scrollTop:position}, 500, "swing");
        }
      }
    }
  });
});
