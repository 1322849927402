/// <reference path="./lib/jquery.d.ts" />

namespace Placeholder{
    export let $placeholder_object01 : any = $('.header__form__text');
}

$(function(){
    if(Placeholder.$placeholder_object01.length){
        if($('html').hasClass('edge')){
            var cont = Placeholder.$placeholder_object01.val();
            if(cont === ''){
                Placeholder.$placeholder_object01.css('text-indent', '22px');
            }
            Placeholder.$placeholder_object01.focus(function(){
            }).blur(function(){
                var cont = $(this).val();
                if(cont === ''){
                    $(this).css('text-indent', '22px');
                }
            });
        }
    }
});