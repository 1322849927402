/// <reference path="./lib/jquery.d.ts" />

namespace Drawer{
    export let $body              = $("body");
    export let $hamburger   : any = $("#hamburger");
    export let $drawer_ham  : any = $("#drawer_ham");
    export let $wrapper           = $("#wrapper");
    export let $overlay     : any = $("#overlay");
    export let $drawer            = $("#drawer");
}

(function($) {
    $.fn.drawer = function() {
        let $this = $(this);
        $this.on('touchstart click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            if(e.type == "touchstart") {
                toggle_drawer();
            }else if(e.type == "click") {
                toggle_drawer();
            }
        });
        function toggle_drawer(){
            // let $hasclasses: string = Drawer.$body.attr("class");
            // let $hasclass: any = $hasclasses.split(' ');
            // for (var i = 0; i < $hasclass.length; i++) {
            //     console.log($hasclass[1]);
            // }
            if($('.drawer-open').length == 1){
                Drawer.$body.removeClass('drawer-open');
                Drawer.$hamburger.removeClass('active');
                Drawer.$drawer_ham.removeClass('active');
                Drawer.$wrapper.removeClass('fixed');
            }else{
                Drawer.$body.addClass('drawer-open');
                Drawer.$hamburger.addClass('active');
                Drawer.$drawer_ham.addClass('active');
                Drawer.$wrapper.addClass('fixed');
            }
        }
        return(this);
    };
})(jQuery);

$(function(){
    Drawer.$hamburger.drawer();
    Drawer.$drawer_ham.drawer();
    Drawer.$overlay.drawer();
});





