/// <reference path="./lib/jquery.d.ts" />

namespace Library{
    export let $library_link : any = $('.biggerlink');
    export let $library_img : any = $('.imgLiquid');
}

$(function(){
    if(Library.$library_link.length){
        Library.$library_link.biggerlink();
    }
    if(Library.$library_img.length){
        Library.$library_img.imgLiquid();
    }
});